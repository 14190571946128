<div class="container-fluid p-5 bg-secondary vh-100">
    <div class="card shadow" style="width:600px">
        <div class="card-body p-0">
            <div class="row">
                <div class="col-sm-6">
                    <img src="../../assets/images/work-5382501_640.jpg" class="img-fluid">
                </div>
                <div class="col-sm-5 pr-0 pt-2">
                    <form class="form-signin">
                        <h1 class="h3 mb-2 text-secondary">Please sign in</h1>
                        <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
                            {{errorMessage}}
                        </div>
                        <div class="form-group">
                            <label for="username" class="sr-only">Username:</label>
                            <input type="email" id="username" class="form-control" [(ngModel)]="loginForm.username"
                                name="username" placeholder="Username:" required autofocus>
                        </div>
    
                        <div class="form-group">
                            <label for="password" class="sr-only">Password:</label>
                            <input type="password" id="password" class="form-control" [(ngModel)]="loginForm.password"
                                name="password" placeholder="Password:" required>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="card-footer text-end">
            <a class="btn btn-outline-secondary mr-2" routerLink="/">Back to Home</a>
            <button class="btn btn-success" type="submit" (click)="login()">Sign in</button>
        </div>
    </div>
</div>

