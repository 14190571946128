<nav class="navbar navbar-expand-lg navbar-expand-md navbar-expand-sm navbar-light bg-transparent">
    <a class="navbar-brand text-secondary" href="javascript:void(0)" (click)="toggleNav()"><i class="fas fa-list-ul"></i></a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav ms-auto">
            <li class="nav-item">
                <a class="nav-link" href="#"><i class="fas fa-sliders-h"></i> Settings</a>
            </li>
            <li *ngIf="!isAuthen" class="nav-item">
                <a class="nav-link" routerLink="/login">Login <i class="fas fa-lock"></i></a>
            </li>
            <li *ngIf="isAuthen" class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-user-circle"></i> {{displayAutnName.username}}
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item" href="#">Profile</a>
                    <a class="dropdown-item" routerLink="/logout">Logout</a>
                </div>
            </li>
        </ul>
    </div>
</nav>