<div class="sidebar">
    <a routerLink="/" class="non-group">Dashboard</a>
    <h6>User & Role</h6>
    <a routerLink="/user">User</a>
    <a routerLink="/role">Role</a>
    <h6>Product</h6>
    <a routerLink="/category">Category</a>
    <a routerLink="/product">Product</a>
    <h6>Order</h6>
    <a routerLink="/order">Order</a>
</div>