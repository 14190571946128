import { Component, OnInit } from '@angular/core';

declare let $;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }
}
