import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor() { }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        document.body.style.backgroundImage = "url('/assets/images/banner.png')";
        document.body.style.backgroundPosition = 'center center';
        document.body.style.backgroundRepeat = 'no-repeat';
        document.body.style.backgroundSize = 'cover';
    }

    ngOnDestroy() {
        document.body.style.backgroundImage = 'none';
        document.body.style.backgroundPosition = 'none';
        document.body.style.backgroundRepeat = 'none';
        document.body.style.backgroundSize = 'none';
    }



}
